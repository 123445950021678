
import {Component, Ref} from 'vue-property-decorator';
import SigInputTexte from '@/components/user/SigInputTexte.vue';
import PwdDef from '@/components/personnal-info/PwdDef.vue';
import UserServicesUtils from '@/services/UserServicesUtils';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {services, store} from '@/main';
import {IUserService} from '@/services/dto/IUserService';
import EmailsSelection from '@/components/EmailsSelection.vue';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import UserCard from '@/components/UserCard.vue';
import SigH4Title from '@/components/user/SigH4Title.vue';
import {IVisioSoftInfoResponse} from '@/services/dto/user-service/IVisioSoftInfoResponse';
import {IVisioSoftServiceRequest} from '@/services/dto/user-service/IVisioSoftServiceRequest';
import ModalDownload from '@/components/user-services/ModalDownload.vue';
import AlertService from '@/utils/AlertService';

const userServicesUtils = new UserServicesUtils();

@Component({
  components: {ModalDownload, SigH4Title, UserCard, EmailsSelection, PwdDef, SigInputTexte } ,
})
export default class VisioActivation extends AbstractComponentVue {
  private baseUrl = services.config.config.$api_url;
  private directoyVisioUrl: string = services.config.config.$directoy_visio_url;

  // Identifiant de l'utilisateur connecter
  private accountConnectedId: number = store.getters.fullUser.accountId;
  // Identifiant de compte en cours de modification
  private accountId: number = +this.$route.params.id;
  // Si l'utilisateur connecté est l'utilisateur qui active son compte
  private isCurrentUser: boolean = this.accountId === this.accountConnectedId;
  @Ref('passwordGen') private readonly passwordGen?: PwdDef;
  @Ref('refEmailVisioSelection') private emailsSelection?: EmailsSelection;

  // on charge les informations de l'utilisateur souhaitant activé la visio
  private userResponse!: IUserResponse;
  // *** Variable utilisé dans le formulaire
  // Identifiant visio proposé par le webservice
  private visioSoftInfoResponse: IVisioSoftInfoResponse = {login: '', accountId: undefined, displayName: ''};
  private contractAccepted: boolean = false;
  private currentMailValue: string = '';
  private showDownloadPage: boolean = false;
  private ShowLastStep: boolean = false;

  // Liste des adresses mail disponible pour l'utilisateur chargé par userResponse
  private usermails: string[] = [];
  private passwordValidity: boolean = false;

  private created() {
    this.loadUserInfoFromWebService();
    this.loadVisioSoftInfoService();
  }

  /**
   * WebService de creation du service Visio.
   * ACTION du Bouton valider
   */
  private activeVisio() {
    this.showModal('modal-traitement-cours');
    const data: IVisioSoftServiceRequest = {
      orderGenId: undefined,
      accountId: this.accountId,
      contractAccepted: this.contractAccepted,
      password: this.isCurrentUser && this.passwordGen !== undefined ? this.passwordGen.newPassword : undefined,
      login: undefined,
      email: this.isCurrentUser ? undefined : this.currentMailValue,
    };
    userServicesUtils.activeVisioAccount(data).then((response: any) => {
      if (response !== undefined && response.status === 201) {
        this.callbackVisioCreatedSucces();
        services.loginService.ifCurrentUserReloadStore(this.accountId,
            () => this.hideModal('modal-traitement-cours'));
      } else {
        this.hideModal('modal-traitement-cours');
      }
    }).catch(((res: any) => {
      this.callbackVisioCreatedKo();
      this.hideModal('modal-traitement-cours');
    }));
  }

  private lastStep() {
    this.showDownloadPage = false;
    this.ShowLastStep = true;
  }

  private callBackWithSucces() {
    this.showDownloadPage = false;
    services.routageService.goTo('/account/' + this.accountId + '/services');
    AlertService.success('Le service de Visioconférence a été activé');
  }

  private callbackVisioCreatedSucces() {
    if (this.isCurrentUser) {
      this.showDownloadPage = true;
    } else {
      this.callBackWithSucces();
    }
  }

  private callbackVisioCreatedKo() {
    AlertService.customAlert(
        'Service Logiciel de visioconférence non-activé',
        '<b>Le service Logiciel de visioconférence n\'a pas pu être activé, contactez votre support</b>',
        'error',
        'Terminer',
        false,
        this.cancelButton,
        true,
    );

  }

  private cancelButton() {
    services.routageService.goTo('/account/' + this.accountId + '/services');
  }

  private watchEmail(email: string) {
    this.currentMailValue = email;
  }

  /**
   * La validation du formulaire, afin de permettre l'accès au bouton de création ballade.
   * @return true si invalid
   */
  private disabledCreationButton(): boolean {
    const hasloginBallade: boolean = false;
    const passwordForCurrentUser = this.isCurrentUser && !this.passwordValidity;
    const hasMailError = !this.isCurrentUser && this.currentMailValue.length === 0;
    return hasloginBallade || passwordForCurrentUser || hasMailError || !this.contractAccepted;
  }

  private watchPasswordValid(valid: boolean) {
    this.passwordValidity = valid;
  }

  /**
   * Appele du webservice permettant l'affichage des informations de l'utilisateur.
   */
  private loadUserInfoFromWebService() {
    userServicesUtils.getAccount(this.accountId).then((response: any) => {
      if (response !== undefined && response.status === 200) {
        this.loadPageWithInfo(response.data);
      } else {
        console.error(response);
      }
    }).catch(((res: any) => {
      console.error(res);
    }));
  }

  /**
   * Appele du webservice permettant l'affichage des informations VISIO de l'utilisateur.
   */
  private loadVisioSoftInfoService() {
    userServicesUtils.getVisioSoftInfoService(this.accountId)
        .then((response: any) => {
          if (response !== undefined && response.status === 200) {
            this.visioSoftInfoResponse = response.data;
          } else {
            console.error(response);
          }
        })
        .catch(((res: any) => {
          console.error(res);
        }));
  }

  /**
   * Chargement du formulaire
   * @param data
   * @private
   */
  private loadPageWithInfo(data: IUserResponse) {
    // génération login ballade
    this.userResponse = data;
    if (!this.isCurrentUser) {
      // récupere le mail par défaut
      const servicemail: IUserService[] = this.userResponse.userServices
          .filter((us) => us.serviceType === 'MAIL' && us.state !== 'DELETED'
              && us.professionalEmail !== undefined);
      if (servicemail.length > 0) {
        const professionalEmail = servicemail[0].professionalEmail;
        if (professionalEmail !== undefined) {
          this.usermails.push(professionalEmail);
          this.emailsSelection?.updateSelectedEmail(professionalEmail);
        }
      }
    }
  }
}
