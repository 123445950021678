
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
})
export default class ModalDownload extends Vue {

  @Prop() private showDownloadPage!: boolean;
  @Prop() private title!: string;
  @Prop() private texte!: string;
  @Prop()  private downloadUrl!: string;

  private downloadButton() {
    window.open(this.downloadUrl, '_blank');
    this.$emit('dowloadClick');
  }
}
